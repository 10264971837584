(function () {
	"use strict";


	if ( $('.main-graph-block') ) {
		
		setTimeout(function() {
			$('.main-graph-block').addClass('appear-lashes');
		}, 1000);

		setTimeout(function() {
			$('.main-graph-block').addClass('rotate-circle');
		}, 1500);

		setTimeout(function() {
			$('.graph-lines').addClass('line-loaded');
		}, 2200);

	}

	applyAnimate();

	$(window).scroll(function() {
		applyAnimate();
	});

	function applyAnimate() {
		$('.animate').each(function(i) {
			var pageTop = $(window).scrollTop();
			var pageBottom = pageTop + $(window).height();

			var $this = $(this);
			var elemenTop = $this.offset().top;
			var elementBottom = elemenTop + $this.height();

			var increament = $this.data('delay');

			if ( (elemenTop <= pageBottom) && (elementBottom >= pageTop) ) {
				// console.log('inside');
				setTimeout(function() {
					$this.addClass('in');			
				}, increament * i);
			}
	
		});	
	}

	$('.slide-single').slick({
		autoplay: true,
		adaptiveHeight: true
	});


	$('.home-page').mousemove(function(e) {
		// debugger;
		var cCoX = e.clientX;
		var cCoY = e.clientY;

		var graphs = $('.graph-eye.holder'),
			gPos = graphs.offset(),
			gWidth = graphs.width(),
			gHeight = graphs.height();
		
		var gCoX = gPos.left;
		var gCoY = gPos.top;

		if ( cCoX <= gCoX ) {
			$(graphs).addClass('left');
			$(graphs).removeClass('right');
		}
		else if ( cCoX > gCoX + gWidth ) {
			$(graphs).addClass('right');
			$(graphs).removeClass('left');
		}
		else {
			$(graphs).removeClass('left right');
		}

		if ( cCoY <= gCoY ) {
			$(graphs).addClass('top');
			$(graphs).removeClass('bottom');
		}
		else if ( cCoY > gCoY + gHeight ) {
			$(graphs).addClass('bottom');
			$(graphs).removeClass('top');
		}
		else {
			$(graphs).removeClass('top bottom');
		}
		
	});


})();
